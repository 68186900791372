<template>
  <div>
    <div
      style="width: 100%; height: 100%"
      ref="dataBar"
    ></div>
  </div>
</template>

<script>
import echarts from 'echarts'

let option = {
  tooltip: {
    trigger: 'axis',
    axisPointer: {
      type: 'shadow',
      lineStyle: {
        color: '#243B45'
      }
    }
  },
  legend: {
    top: 'middle',
    right: '0',
    orient: 'vertical',
    textStyle: {
      color: '#fff'
    },
    data: ['正常', '到期']
  },
  grid: {
    top: '5%',
    left: '3%',
    right: '15%',
    bottom: '5%',
    containLabel: true
  },
  xAxis: {
    type: 'value',
    axisLine: {
      lineStyle: {
        color: 'rgba(255,255,255,0.6)'
      }
    },
    axisTick: {
      show: true
    },
    splitLine: {
      lineStyle: {
        color: 'rgba(255,255,255,.4)'
      }
    }
  },
  yAxis: {
    type: 'category',
    axisLine: {
      lineStyle: {
        color: 'rgba(255,255,255,0.6)'
      }
    },
    axisTick: {
      show: false
    },
    data: []
  },
  series: [
    {
      name: '到期',
      type: 'bar',
      barWidth: '15px',
      barGap: '-100%',
      itemStyle: {
        color: {
          type: 'linear',
          x: 0.2,
          y: 0,
          x2: 0.8,
          y2: 1,
          colorStops: [
            {
              offset: 0,
              color: '#0579FF' // 0% 处的颜色
            },
            {
              offset: 1,
              color: '#01FEFC' // 100% 处的颜色
            }
          ],
          global: false // 缺省为 false
        },
        barBorderRadius: 20
      },
      z: 2,
      data: []
    },
    {
      name: '正常',
      type: 'bar',
      barWidth: '15px',
      barBorderRadius: 20,
      stack: 1,
      itemStyle: {
        color: '#0E2F3C',
        barBorderRadius: 20
      },
      z: 1,
      data: []
    }
  ]
}
export default {
  props: {
    barData: {
      type: Object,
      default: function() {
        return {}
      }
    }
  },
  data() {
    return {}
  },
  watch: {
    barData: 'initBar'
  },
  methods: {
    initBar() {
      let dataBar = echarts.init(this.$refs.dataBar)
      if (this.barData.normalData.length === 0) return
      option.yAxis.data = this.barData.yData
      option.series[0].data = this.barData.expireData
      option.series[1].data = this.barData.normalData
      dataBar.setOption(option)
      window.addEventListener('resize', function() {
        dataBar.resize()
      })
    }
  },
  mounted() {
    // this.initBar()
  }
}
</script>

<style lang='sass' scoped>

</style>
